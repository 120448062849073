import React from "react";

export default () => (
  <div className="container">
    <footer>
      <p>
        &copy;{" "}
        <a href="mailto:k.ivanow@gmail.com" className="mail">
          Kristiyan Ivanov
        </a>
      </p>
    </footer>
  </div>
);
