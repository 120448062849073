import React from "react";
import styled from "styled-components";

const BorderedDiv = styled.div`
  border-bottom: solid 1px rgba(131, 211, 247, 1);
`;

export default () => (
  <BorderedDiv className="jumbotron borderBottom">
    <div className="container">
      <h1>Hello, world,</h1>
      <p>
        I am Kristiyan Ivanov and bellow you can see what I have worked on or I
        am still working in my part time. I am mostly focused on web
        development, hybrid mobile apps, desktop apps with Electron and
        unit/integration testing. I also like to automate as much as I can for
        my day to day job with Python or try making plugins/extensions for
        something I like and use (like Sublime Text and VS Code).
      </p>
    </div>
  </BorderedDiv>
);
