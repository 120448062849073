import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const StyledA = styled.a`
  position: absolute;
  bottom: 15px;
  border: solid 1px rgba(131, 211, 247, 1);
`;

const StyledContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-width: 33.3%;
  padding: 15px;
  min-width: 300px;
  @media (max-width: 650px) {
    max-width: unset;
  }
`;

const StyledAContainer = styled.div`
  flex-grow: 1;
  min-height: 60px;
  position: relative;
`;

const ProjectCard = props => (
  <StyledContainer>
    <h3>{props.data.name}</h3>
    {props.data.description}
    <StyledAContainer>
      <StyledA
        className="btn btn-default"
        href={props.data.link.href}
        role="button"
      >
        {props.data.link.text} &raquo;
      </StyledA>
    </StyledAContainer>
  </StyledContainer>
);

export default ProjectCard;

ProjectCard.propTypes = {
  data: PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.object,
    link: PropTypes.shape({
      text: PropTypes.string,
      href: PropTypes.string
    })
  })
};
