import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

let H2 = styled.h2`
  margin-top: 70px;
  margin-bottom: -5px;
  border-bottom: solid 2px rgba(131, 211, 247, 1);
`;

let FlexDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Row = props => (
  <Fragment>
    {props.header ? (
      <div>
        <div>
          <H2>{props.header}</H2>
        </div>
      </div>
    ) : (
      ""
    )}
    <FlexDiv>{props.children}</FlexDiv>
  </Fragment>
);

export default Row;

Row.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
