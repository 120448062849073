import React, { Fragment } from "react";
import styled from "styled-components";
import Header from "../components/header";
import ProjectCard from "../components/projectCard";
import Row from "../components/row";
import Contacts from "../components/contacts";
import Footer from "../components/footer";
import Gallery from "../components/gallery";

const data = {
  rows: [
    {
      header: "Recent web and native solutions",
      projects: [
        {
          name: "Janus Workspace",
          description: (
            <Fragment>
              <p>
                A different take on how browsers can work. Made with{" "}
                <a href="https://electronjs.org/">Electron</a>
              </p>
              <p>It also exists as a Chrome Extension</p>
            </Fragment>
          ),
          link: {
            href: "https://janusworkspace.com",
            text: "Check it out here"
          }
        },

        {
          name: "Bird Of Knowledge",
          description: (
            <Fragment>
              <p>
                Seach engine that not just searches for information but also
                generates a summary for you
              </p>
              <p>It was made as a part of the Product Hunt Global Hackaton</p>
            </Fragment>
          ),
          link: {
            href: "https://birdofknowledge.com",
            text: "Check it out here"
          }
        },

        {
          name: "interactiveCV",
          description: (
            <Fragment>
              <p>
                A game version of my CV, full of kittens, zombies and rock
                music.... <i>what more can you possibly want</i>
              </p>
              <p>
                I also wrote a{" "}
                <a href="https://medium.com/@k_ivanow/what-i-learned-from-my-latest-weekend-project-that-turned-not-so-small-ac6e350aea4c">
                  Medium
                </a>{" "}
                story about some interesting aspects of it.
              </p>
            </Fragment>
          ),
          link: {
            href: "http://kristiyan.quatinus.info",
            text: "Check it out here"
          }
        }
      ]
    },
    {
      header: (
        <Fragment>
          <p>Android hybrid and native apps.</p>{" "}
          <p>
            <i>
              {
                "//some of them may be inactive due to missing privacy policies because they were made a few years ago"
              }
            </i>
          </p>
        </Fragment>
      ),
      projects: [
        {
          name: "Be Positive",
          description: (
            <Fragment>
              <p>
                A <a href="https://cordova.apache.org/">cordova</a> app that
                allows users to share why they are happy, see why others were
                happy and remind them why they were happy before.
              </p>
              <p>
                It also has a web version{" "}
                <a href="https://bepositive.quatinus.info">
                  https://bepositive.quatinus.info
                </a>{" "}
              </p>
            </Fragment>
          ),
          link: {
            href:
              "https://play.google.com/store/apps/details?id=info.quatinus.stayHappy",
            text: "Download for android"
          }
        },

        {
          name: "Audio Hero",
          description: (
            <Fragment>
              <p>
                My first <a href="https://cordova.apache.org/">cordova</a> app,
                which I should redesign at some point.
              </p>
              <p>
                The goal is to get as much points as possible using your device
                accelerometer. It also dynamically generates the song notes from
                the webAudio API.
              </p>
            </Fragment>
          ),
          link: {
            href: "https://play.google.com/store/apps/details?id=info.quatinus",
            text: "Download for android"
          }
        },

        {
          name: "Mad Call",
          description: (
            <Fragment>
              <p>
                {" "}
                App made with <a href="http://www.pixijs.com/">PixiJS</a>,{" "}
                <a href="https://cordova.apache.org/">cordova</a> and a lot of
                java in a plugin.
              </p>
              <p>
                It allows the user to unleash their anger on the last person
                they talked on the phone to.
              </p>
            </Fragment>
          ),
          link: {
            href:
              "https://play.google.com/store/apps/details?id=info.quatinus.angercall",
            text: "Download for android"
          }
        }
      ]
    },
    {
      header: null,
      projects: [
        {
          name: "Gesture Controller",
          description: (
            <Fragment>
              <p>My most downloaded app. Rewritten completely in java. </p>
              <p>
                It allows the users to wake up their devices just by moving them
                and to teach them 3d gestures, with which later they can open
                apps.
              </p>
            </Fragment>
          ),
          link: {
            href:
              "https://play.google.com/store/apps/details?id=info.quatinus.gestureControllerFree",
            text: "Download for android"
          }
        },
        {
          name: "Balancing Heroes",
          description: (
            <Fragment>
              <p>
                My first <a href="https://cordova.apache.org/">cordova</a> +{" "}
                <a href="http://www.pixijs.com/">PixiJS</a> app
              </p>
              <p>
                The goal is to keep your hero balanced for as long as possible.
                Each hero has unique spells and abilities.{" "}
              </p>
            </Fragment>
          ),
          link: {
            href:
              "https://play.google.com/store/apps/details?id=info.quatinus.balance",
            text: "Download for android"
          }
        }
      ]
    },
    {
      header: "Random Open Source projects",
      projects: [
        {
          name: "installedApps plugin",
          description: (
            <Fragment>
              <p>
                Installed apps is a{" "}
                <a href="https://cordova.apache.org/">cordova</a> plugin.
              </p>
              <p>
                It returns the installed apps on the user's <u>android</u>{" "}
                device. It returns all of the apps names, packages, etc.
              </p>
            </Fragment>
          ),
          link: {
            href: "https://github.com/KIvanow/installedApps",
            text: "Get it on Github"
          }
        },
        {
          name: "Export Markers",
          description: (
            <Fragment>
              <p>Export Markers is a Sublime Text plugin. </p>
              <p>
                It generates json from opened audio sprite, that desscribes
                every sound with its beginning, end, duration, loop and so on.
              </p>
            </Fragment>
          ),
          link: {
            href: "https://github.com/KIvanow/ExportMarkers",
            text: "Get it on Github"
          }
        }
      ]
    },
    {
      header: "Miscellaneous",
      projects: [
        {
          name: "Quatinus",
          description: (
            <Fragment>
              <p>Quatinus (Wherebg) is my old diploma project.</p>
              <p>
                It gathers information about venues from foursquare, twitter,
                forums and so on, connects it, evaluates it and then presents it
                to the users with ratings for key categories, based on the
                comments it has found.
              </p>
            </Fragment>
          ),
          link: {
            href: "https://diploma.quatinus.info",
            text: "Open"
          }
        },
        {
          name: "Medium Articles",
          description: (
            <Fragment>
              <p>
                Here you can find some of my articles on{" "}
                <a href="https://medium.com">Medium</a>
              </p>
            </Fragment>
          ),
          link: {
            href: "https://medium.com/@k_ivanow",
            text: "Open"
          }
        },
        {
          name: "How to Build a Career in Tech",
          description: (
            <Fragment>
              <p>I helped Product Hunt with their first book.</p>
              <p />
              <p />
            </Fragment>
          ),
          link: {
            href: "https://books.producthunt.com/careers?ref=producthunt",
            text: "Open"
          }
        }
      ]
    }
  ]
};

let Hr = styled.hr`
  border-color: rgba(131, 211, 247, 1);
`;

let projectsData = data.rows.map((row, i) => {
  let projects = row.projects.map((project, i) => {
    return <ProjectCard key={i} data={project} />;
  });
  return (
    <Row key={i} header={row.header}>
      {projects}
    </Row>
  );
});
export default () => (
  <div>
    <Header />
    <Gallery />
    <Hr />
    <div className="container">{projectsData}</div>
    <Hr />
    <Contacts />
    <Hr />
    <Footer />
  </div>
);
