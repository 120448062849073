import React from "react";
import Gallery from "react-photo-gallery";

const PHOTO_SET = [
  {
    src: "https://quatinus.info/indexImages/galleryImages/interactiveCV.png",
    width: 1.75,
    height: 1
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/darkness.gif",
    width: 1.75,
    height: 1
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/audioHero1.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/audioHero2.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/audioHero3.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/audioPlayer1.png",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/audioPlayer2.png",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/audioPlayer3.png",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/balancingHeroes1.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/balancingHeroes2.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/balancingHeroes3.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/balancingHeroes4.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/balancingHeroes5.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/balancingHeroes6.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/bePositive1.png",
    width: 506,
    height: 900
  },
  {
    src:
      "https://quatinus.info/indexImages/galleryImages/gestureController1.png",
    width: 506,
    height: 900
  },
  {
    src:
      "https://quatinus.info/indexImages/galleryImages/gestureController2.png",
    width: 506,
    height: 900
  },
  {
    src:
      "https://quatinus.info/indexImages/galleryImages/gestureController3.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/madCall1.png",
    width: 500,
    height: 500
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/madCall2.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/madCall3.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/madCall6.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/madCall7.png",
    width: 506,
    height: 900
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/madCall8.png",
    width: 506,
    height: 900
  },
  {
    src:
      "https://quatinus.info/indexImages/galleryImages/Product Hunt HowToBuildACareerInTech.png",
    width: 1905,
    height: 965
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/JanusWorkspace1.jpg",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/JanusWorkspace2.jpg",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/JanusWorkspace3.jpg",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/JanusWorkspace4.jpg",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/JanusWorkspace5.jpg",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/Gamayun1.jpg",
    width: 640,
    height: 400
  },
  {
    src: "https://quatinus.info/indexImages/galleryImages/Gamayun2.jpg",
    width: 640,
    height: 400
  }
];

export default () => {
  return (
    <div
      style={{
        maxHeight: "550px",
        overflowY: "auto"
      }}
    >
      <Gallery photos={PHOTO_SET} />
    </div>
  );
};
