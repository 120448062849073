import React from "react";
import styled from "styled-components";
import {
  FaTwitter,
  FaLinkedin,
  FaEnvelope,
  FaPhone,
  FaGithub,
  FaFacebook
} from "react-icons/fa";

const H2 = styled.h2`
  margin-top: 70px;
  margin-bottom: -5px;
  border-bottom: solid 2px rgba(131, 211, 247, 1);
  margin-top: 20px;
  margin-bottom: 0px;
`;

const DivFloatLeft = styled.div`
  float: left;
`;

const marginLeft = "30px";

const FaFontSize = "1.6em";

export default () => (
  <div className="container">
    <div
      className=""
      id="contacts"
      style={{
        background: "none",
        border: "none",
        boxShadow: "none"
      }}
    >
      <div>
        <div>
          <H2 className="title-contact"> Contacts </H2>
          <div>
            <br />
            <p>
              If by any chance you want to share something about any of the
              projects, listed above, have an idea or have something that I can
              help you with - you can find me at any of the links next.
            </p>
          </div>
          <br />

          <div className="row contact">
            <DivFloatLeft className="col-md-1">
              <a href="mailto:k.ivanow@gmail.com+" className="mail">
                <FaEnvelope style={{ fontSize: FaFontSize }} />
              </a>
            </DivFloatLeft>
            <DivFloatLeft className="col-md-1">
              <a href="tel:+359886352924" className="phone">
                <FaPhone style={{ fontSize: FaFontSize }} />
              </a>
            </DivFloatLeft>
            <DivFloatLeft className="col-md-1">
              <a href="https://github.com/KIvanow" className="github">
                <FaGithub style={{ fontSize: FaFontSize }} />
              </a>
            </DivFloatLeft>
            <DivFloatLeft className="col-md-1">
              <a
                href="https://www.facebook.com/kristian.ivanov.737"
                className="facebook"
              >
                <FaFacebook style={{ fontSize: FaFontSize }} />
              </a>
            </DivFloatLeft>
            <DivFloatLeft className="col-md-1">
              <a
                href="https://www.linkedin.com/in/kristiyan-ivanov-8b3b5671?trk=hp-identity-name"
                className="linkedin"
              >
                <FaLinkedin style={{ fontSize: FaFontSize }} />
              </a>
            </DivFloatLeft>
            <DivFloatLeft className="col-md-1">
              <a href="https://twitter.com/k_ivanow" className="twitter">
                <FaTwitter style={{ fontSize: FaFontSize }} />
              </a>
            </DivFloatLeft>
          </div>
        </div>
      </div>
    </div>
  </div>
);
